<template>
  <div class="pps-icon">
    <svg
      t="1724756288237"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4941"
      width="20"
      height="20"
    >
      <path
        d="M931.1 93.2C910.5 72.4 885.6 62 856.5 62h-689c-29.1 0-53.9 10.4-74.5 31.2C72.3 114 62 139 62 168.4v79.1c0 29.3 10.3 54.4 30.9 75.2 20.6 20.8 45.5 31.2 74.5 31.2h689.1c29.1 0 53.9-10.4 74.5-31.2 20.6-20.8 30.9-45.9 30.9-75.2v-79.1c0.1-29.3-10.2-54.4-30.8-75.2z m-36.6 154.3c0.1 10.5-3.2 19.2-9.9 26-9.2 9.1-18.5 13.6-28 13.6l-689.1-0.4c-11 0.5-19.8-2.7-26.6-9.5-7.2-9.9-10.9-19.7-10.9-29.3v-79.1c0.5-11.5 4.1-20.7 10.9-27.5 8.2-7.5 17.1-11.3 26.6-11.3l689.1 0.2c10.6 1 19.3 5 26 11.8 8 8 11.9 16.8 11.9 26.5v79z"
        p-id="4942"
        fill="#515151"
      ></path>
      <path
        d="M218.024259 222.162027a35.9 35.9 0 1 0 71.555371-5.921901 35.9 35.9 0 1 0-71.555371 5.921901Z"
        p-id="4943"
        fill="#515151"
      ></path>
      <path
        d="M440.8 740.7c6.9-6.7 10.4-14.8 10.4-24.2 0-9.5-3.5-17.5-10.4-24.2-6.9-6.7-15.3-10-25.1-10H167.4c-29.1 0-53.9 10-74.5 29.9S62 756.2 62 784.3V860c0 28.2 10.3 52.2 30.9 72.1 20.6 19.9 45.5 29.9 74.6 29.9h248.3c9.8 0 18.1-3.3 25.1-10 6.9-6.7 10.4-14.8 10.4-24.2 0-9.5-3.5-17.5-10.4-24.2-6.9-6.7-15.3-10-25.1-10H167.5c-9.6 0-17.7-3.3-24.5-9.8-6.8-6.5-10.1-14.4-10.1-23.7v-75.8c0-9.2 3.4-17.1 10.1-23.6 6.8-6.5 14.9-9.8 24.4-9.8h248.3c9.8-0.1 18.2-3.5 25.1-10.2z"
        p-id="4944"
        fill="#515151"
      ></path>
      <path
        d="M217.9 831.9c0 19.8 16.1 35.9 35.9 35.9s35.9-16.1 35.9-35.9-16.1-35.9-35.9-35.9-35.9 16-35.9 35.9zM217.9 520.8c0 19.8 16.1 35.9 35.9 35.9s35.9-16.1 35.9-35.9c0-19.8-16.1-35.9-35.9-35.9s-35.9 16.1-35.9 35.9z"
        p-id="4945"
        fill="#515151"
      ></path>
      <path
        d="M931.1 408.1c-20.6-19.9-45.5-29.9-74.5-29.9H167.5c-29.1 0-53.9 10-74.5 29.9-20.7 19.9-31 44-31 72.1V556c0 28.1 10.3 52.1 30.9 72.1 20.6 19.9 45.5 29.9 74.5 29.9H416c9.8 0 18.1-3.3 25.1-10 6.9-6.7 10.4-14.8 10.4-24.2 0-9.5-3.5-17.5-10.4-24.2-6.9-6.7-15.3-10-25.1-10H167.5c-9.5 0-17.7-3.3-24.4-9.8-6.8-6.5-10.1-14.4-10.1-23.6v-75.8c-0.7-9.6 2.4-17.7 9.2-24.3 7.4-7.3 15.9-11 25.4-11l689.1 1.8c9.5 0 17.7 3.3 24.4 9.8 6.8 6.5 10.1 14.4 10.1 23.6v21.9c0 9.5 3.5 17.5 10.4 24.2 6.9 6.7 15.3 10 25.1 10s18.1-3.3 25.1-10c6.9-6.7 10.4-14.8 10.4-24.2v-21.9c-0.2-28.2-10.5-52.3-31.1-72.2z"
        p-id="4946"
        fill="#515151"
      ></path>
      <path
        d="M847.9 557H581.1c-63 0-114.1 49.4-114.1 110.2v184.5c0 60.9 51.1 110.2 114.1 110.2h266.8c63 0 114.1-49.4 114.1-110.2V667.2c0-60.8-51.1-110.2-114.1-110.2z m50.2 294.8c0 13.4-4.9 24.8-14.7 34.3-9.8 9.5-21.6 14.2-35.5 14.2H581.1c-13.9 0-25.7-4.7-35.5-14.2s-14.7-20.9-14.7-34.3V667.2c0-13.4 4.9-24.8 14.7-34.3 9.8-9.5 21.6-14.2 35.5-14.2h266.8c13.9 0 25.7 4.7 35.5 14.2s14.7 20.9 14.7 34.3v184.6z"
        p-id="4947"
        fill="#515151"
      ></path>
      <path
        d="M841.3 676.7c-5.4-4.8-12-7.2-19.7-7.2H607.3c-7.7 0-14.2 2.4-19.7 7.2s-8.2 10.5-8.2 17.2v84.4c0 6.7 2.7 12.4 8.2 17.2 5.4 4.8 12 7.2 19.7 7.2h20.3l14.4 31.1c4.5 10.5 13.3 15.8 26.3 15.8h92.2c13 0 21.8-5.3 26.3-15.8l14.4-31.1h20.3c7.7 0 14.2-2.4 19.7-7.2 5.4-4.8 8.2-10.5 8.2-17.2v-84.4c0.1-6.7-2.6-12.5-8.1-17.2z m-26 95.9H797c-13 0-21.8 5.3-26.3 15.8l-14.4 31.1h-83.5l-14.4-31.1c-4.5-10.5-13.3-15.8-26.3-15.8h-18.2v-73.2h201.5v73.2z"
        p-id="4948"
        fill="#515151"
      ></path>
      <path
        d="M789.5 759.5c4.7 0 8.5-2.5 11.4-7.6 2.4-4.2 3.6-9.2 3.6-14.9s-1.2-10.7-3.6-14.9c-2.9-5-6.7-7.6-11.4-7.6s-8.5 2.5-11.4 7.6c-2.4 4.2-3.6 9.2-3.6 14.9s1.2 10.7 3.6 14.9c2.9 5.1 6.7 7.6 11.4 7.6zM639.5 759.5c4.7 0 8.5-2.5 11.4-7.6 2.4-4.2 3.6-9.2 3.6-14.9s-1.2-10.7-3.6-14.9c-2.9-5-6.7-7.6-11.4-7.6s-8.5 2.5-11.4 7.6c-2.4 4.2-3.6 9.2-3.6 14.9s1.2 10.7 3.6 14.9c2.9 5.1 6.7 7.6 11.4 7.6zM689.5 759.5c4.7 0 8.5-2.5 11.4-7.6 2.4-4.2 3.6-9.2 3.6-14.9s-1.2-10.7-3.6-14.9c-2.9-5-6.7-7.6-11.4-7.6s-8.5 2.5-11.4 7.6c-2.4 4.2-3.6 9.2-3.6 14.9s1.2 10.7 3.6 14.9c2.9 5.1 6.7 7.6 11.4 7.6zM739.5 759.5c4.7 0 8.5-2.5 11.4-7.6 2.4-4.2 3.6-9.2 3.6-14.9s-1.2-10.7-3.6-14.9c-2.9-5-6.7-7.6-11.4-7.6s-8.5 2.5-11.4 7.6c-2.4 4.2-3.6 9.2-3.6 14.9s1.2 10.7 3.6 14.9c2.9 5.1 6.7 7.6 11.4 7.6z"
        p-id="4949"
        fill="#515151"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-port'
};
</script>

<style></style>
