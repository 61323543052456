<template>
  <svg
    t="1721583939410"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="5461"
    width="24"
    height="24"
  >
    <path
      d="M512 85.3504a426.6496 426.6496 0 1 1 0 853.2992 426.6496 426.6496 0 0 1 0-853.2992z m188.7232 279.04L461.824 603.2896 343.808 485.2736l-45.1584 45.1072 133.12 133.1712 4.352 3.84a42.6496 42.6496 0 0 0 55.9616-3.84l253.952-253.8496-45.312-45.312z"
      fill="#07C160"
      p-id="5462"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'pps-icon-online'
};
</script>
