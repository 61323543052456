<template>
  <div v-on="$listeners" class="pps-icon-group">
    <svg
      t="1725373394462"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1521"
      width="20"
      height="20"
    >
      <path
        d="M482.399 20.67C216.884 20.67 2 207.593 2 438.173c0 141.471 82.104 267.268 206.136 344.098v129.283c0 17.463 12.22 27.939 26.208 27.939 5.236 0 10.496-1.73 13.967-3.494l131.017-87.34c33.208 6.984 66.383 8.748 101.329 8.748 265.519 0 480.38-186.928 480.38-417.504 1.77-232.31-213.096-419.233-478.638-419.233z m0 766.859c-29.697 0-59.402-3.494-89.088-8.748l-13.983-3.494c-8.729-1.725-17.462 0-26.214 5.258l-12.202 8.715-62.897 41.939v-68.115c0-12.242-6.982-24.484-17.461-29.703l-17.477-10.479C134.764 658.244 70.138 551.711 70.138 439.904c0-192.147 183.433-347.601 410.519-347.601 227.103 0 410.503 155.454 410.503 347.601 1.764 190.401-183.4 347.625-408.761 347.625zM209.878 450.376c0 31.857 25.807 57.659 57.66 57.659 31.836 0 57.642-25.801 57.642-57.659 0-31.797-25.806-57.62-57.642-57.62-31.853 0-57.66 25.824-57.66 57.62z m218.378 0c0 31.857 25.801 57.659 57.637 57.659 31.835 0 57.658-25.801 57.658-57.659 0-31.797-25.822-57.62-57.658-57.62-31.836 0-57.637 25.824-57.637 57.62z m211.349 0c0 31.857 25.824 57.659 57.643 57.659 31.854 0 57.676-25.801 57.676-57.659 0-31.797-25.822-57.62-57.676-57.62-31.818 0-57.643 25.824-57.643 57.62z m330.891 90.263c-1.031-1.301-2.117-2.547-3.18-3.848-7.514 28.535-18.244 56.021-31.754 82.121 0.563 1.268 1.268 2.475 1.809 3.742 9.139 21.021 14.104 43.742 14.104 67.438 0 62.834-36.301 122.688-97.15 158.992l-9.807 5.887c-5.887 2.928-9.844 9.807-9.844 16.689v38.27l-35.309-23.568-6.879-4.895c-4.895-2.928-9.811-3.92-14.705-2.928l-7.838 1.936c-16.723 2.998-33.412 4.895-50.047 4.895-40.004 0-77.678-8.852-110.551-24.273-32.635 7.621-66.5 12.422-101.378 14.07 54.632 47.082 129.826 76.195 212.888 76.195 22.014 0 42.893-1.102 63.771-5.473l82.469 54.963c2.182 1.104 5.473 2.184 8.74 2.184 8.814 0 16.508-6.592 16.508-17.572v-81.381c78.059-48.373 129.748-127.533 129.748-216.555 0.001-54.401-19.015-104.956-51.595-146.889z m0 0"
        fill="#8a8a8a"
        p-id="1522"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-group'
};
</script>
