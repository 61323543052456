<template>
  <div v-on="$listeners" class="pps-icon-account">
    <svg
      t="1725364158167"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2049"
      width="20"
      height="20"
    >
      <path
        d="M864 128h-704a128 128 0 0 0-128 128v512a128 128 0 0 0 128 128h704a128 128 0 0 0 128-128V256a128 128 0 0 0-128-128z m64 640a64 64 0 0 1-64 64h-704a64 64 0 0 1-64-64V256a64 64 0 0 1 64-64h704a64 64 0 0 1 64 64v512z"
        fill="#8a8a8a"
        p-id="2050"
      ></path>
      <path
        d="M832 480h-192a32 32 0 0 0 0 64h192a32 32 0 0 0 0-64zM832 608h-192a32 32 0 0 0 0 64h192a32 32 0 0 0 0-64zM832 352h-192a32 32 0 0 0 0 64h192a32 32 0 0 0 0-64zM384 576a192 192 0 0 0-192 192h64a128 128 0 0 1 256 0h64a192 192 0 0 0-192-192zM384 512a128 128 0 1 0-0.064-256.064A128 128 0 0 0 384 512z m0-191.488a63.488 63.488 0 1 1 0 126.976 63.488 63.488 0 0 1 0-126.976z"
        fill="#8a8a8a"
        p-id="2051"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-account'
};
</script>

<style scoped>
/* .pps-icon-account {
  width: 20px;
  height: 20px;
} */
</style>
