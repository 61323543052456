<template>
  <div v-on="$listeners" class="pps-icon">
    <svg
      aria-hidden="true"
      focusable="false"
      class="octicon octicon-sidebar-collapse"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      fill="currentColor"
      style="
        display: inline-block;
        user-select: none;
        vertical-align: text-bottom;
        overflow: visible;
      "
    >
      <path
        d="M6.823 7.823a.25.25 0 0 1 0 .354l-2.396 2.396A.25.25 0 0 1 4 10.396V5.604a.25.25 0 0 1 .427-.177Z"
      ></path>
      <path
        d="M1.75 0h12.5C15.216 0 16 .784 16 1.75v12.5A1.75 1.75 0 0 1 14.25 16H1.75A1.75 1.75 0 0 1 0 14.25V1.75C0 .784.784 0 1.75 0ZM1.5 1.75v12.5c0 .138.112.25.25.25H9.5v-13H1.75a.25.25 0 0 0-.25.25ZM11 14.5h3.25a.25.25 0 0 0 .25-.25V1.75a.25.25 0 0 0-.25-.25H11Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-side-hide'
};
</script>

<style></style>
