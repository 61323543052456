<template>
  <div v-on="$listeners" class="pps-icon-contact">
    <svg
      t="1725363712634"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="29231"
      width="20"
      height="20"
    >
      <path
        d="M832 64H256a128 128 0 0 0-128 128v64h-32a32 32 0 0 0 0 64H128v160h-32a32 32 0 0 0 0 64H128V704h-32a32 32 0 0 0 0 64H128v64a128 128 0 0 0 128 128h576a128 128 0 0 0 128-128V192a128 128 0 0 0-128-128z m64 768a64 64 0 0 1-64 64H256a64 64 0 0 1-64-64v-64h32a32 32 0 0 0 0-64H192V544h32a32 32 0 0 0 0-64H192V320h32a32 32 0 0 0 0-64H192V192a64 64 0 0 1 64-64h576a64 64 0 0 1 64 64v640z"
        fill="#8A8A8A"
        p-id="29232"
      ></path>
      <path
        d="M544 576a192 192 0 0 0-192 192h64a128 128 0 0 1 256 0h64a192 192 0 0 0-192-192zM544 512a128 128 0 1 0-0.064-256.064A128 128 0 0 0 544 512z m0-191.488a63.488 63.488 0 1 1 0 126.976 63.488 63.488 0 0 1 0-126.976z"
        fill="#8A8A8A"
        p-id="29233"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'pps-icon-contact'
};
</script>
